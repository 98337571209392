<template>
    <div class="admin-settings-content">
        <el-card>
            <el-form :label-position="labelPosition" label-width="auto">
<!--                <el-form-item label="设置人员总数:">-->
<!--                    <el-input v-model="config.maxPersonTotal"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="设置记录总数:">-->
<!--                    <el-input v-model="config.maxRecordTotal"></el-input>-->
<!--                </el-form-item>-->
                <el-form-item label="设置服务器IP:">
                    <el-input v-model="config.ip"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="setConfig()">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "settings",
        data() {
            return {
                labelPosition: 'left',
                config: {}
            }
        },
        mounted() {
            this.getConfig()
        },
        methods: {
            getConfig() {
                setTimeout(() => {
                    axios.get('/config/getConfig')
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.config = res.data.data
                            }
                        })
                })
            },
            setConfig() {
                setTimeout(() => {
                    axios.post('/config/setConfig', this.config)
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg
                                });
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.data.msg
                                });
                            }
                        })
                })
            }
        }
    }
</script>

<style scoped>
    .admin-settings-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
</style>